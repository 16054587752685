.unit-select-row {
  width: 100%;
  display: flex;
  align-items: center;
  height: 36px;

  > select {
    width: calc(100% - 36px);
  }
  .unit-action {
    width: 36px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      filter: brightness(120%);
    }
  }
}
