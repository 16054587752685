//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

// Colors
// Defaults
$blue: #2c7be5 !default;
$indigo: #727cf5 !default;
$purple: #6b5eae !default;
$pink: #ff679b !default;
$red: #e63757 !default;
$orange: #fd7e14 !default;
$yellow: #f5803e !default;
$green: #00d27a !default;
$teal: #02a8b5 !default;
$cyan: #27bcfd !default;

$white: #fff !default;
$gray-100: #f9fafd !default;
$gray-200: #edf2f9 !default;
$gray-300: #d8e2ef !default;
$gray-400: #b6c1d2 !default;
$gray-500: #9da9bb !default;
$gray-600: #748194 !default;
$gray-700: #5e6e82 !default;
$gray-800: #4d5969 !default;
$gray-900: #344050 !default;
$gray-1000: #232e3c !default;
$gray-1100: #0b1727 !default;
$black: #000 !default;

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-1100 !default;

$primary: #032A4E;
$primary-dark: #021F39;
$primary-light: #1bbfd8;
$secondary: #00A99D;
$secondary-dark: #00867D;
$secondary-light: #4BDDD2;
$secondary2: #6EC05D;
$secondary2-dark: #528946;
$secondary2-light: #bbd760;
$success: $secondary2;
$success-dark: $secondary2-dark;
$success-light: $secondary2-light;
$info: $primary-light;
$dark: $primary-dark;

$dark-grays: (
  'black': $white,
  '100': $gray-1100,
  '200': $gray-1000,
  '300': $gray-900,
  '400': $gray-800,
  '500': $gray-700,
  '600': $gray-600,
  '700': $gray-500,
  '800': $gray-400,
  '900': $gray-300,
  '1000': $gray-200,
  '1100': $gray-100,
  'white': $black,
);

$theme-dark-colors: (
  'primary': $primary-light,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': rgba(map-get($dark-grays, '200'), 0.25), //TODO
  'dark': $light,
);

$theme-dark-colors-soft: () !default;
$theme-dark-colors-soft: map-merge(
    (
      'primary': shift-color(map-get($theme-dark-colors, 'primary'), 70%),
      'secondary': shift-color(map-get($theme-dark-colors, 'secondary'), 70%),
      'success': shift-color(map-get($theme-dark-colors, 'success'), 70%),
      'info': shift-color(map-get($theme-dark-colors, 'info'), 70%),
      'warning': shift-color(map-get($theme-dark-colors, 'warning'), 70%),
      'danger': shift-color(map-get($theme-dark-colors, 'danger'), 70%),
      'light': shift-color(map-get($theme-dark-colors, 'light'), 70%),
      'dark': shift-color(map-get($theme-dark-colors, 'dark'), 70%),
    ),
    $theme-dark-colors-soft
);

// Fonts
$font-family-sans-serif: 'Roboto', -apple-system, BlinkMacSystemFont,
'Segoe UI', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$font-family-monospace: 'SFMono-Regular', Menlo, Monaco, Consolas,
'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
'Segoe UI Emoji', 'Segoe UI Symbol' !default;

$headings-font-family: $font-family-sans-serif !default;
//$headings-font-weight: $font-weight-medium !default;
//$headings-color: var(--#{$variable-prefix}headings-color) !default;
