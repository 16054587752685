
/* -------------------------------------------------------------------------- */
/*                             Bootstrap Carousel                             */
/* -------------------------------------------------------------------------- */

.theme-slider{
  @include hover-focus{
    .carousel-control-next,
    .carousel-control-prev{
      color: $gray-700;
      opacity: 0.5 !important;
    }
  }
  .carousel-control-prev,
  .carousel-control-next{
    width: 3rem;
    height: 3rem;
    background: $white;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0.125rem 0.125rem 0.25rem rgba($black, 0.1);
    transition: opacity 0.4s ease-in-out;
    color: $gray-700;
    opacity: 0;
    @include hover-focus{
      opacity: 1 !important;
    }
  }
  .carousel-control-prev{
    left: map_get($spacers, 2);
  }
  .carousel-control-next{
    right: map_get($spacers, 2);
  }
}
