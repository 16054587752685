/*-----------------------------------------------
|   Table
-----------------------------------------------*/
.table {
  tfoot > tr > th:first-child,
  thead > tr > th:first-child,
  tr th:first-child,
  tr td:first-child {
    padding-left: $card-spacer-x;
  }
  tfoot > tr > th:last-child,
  thead > tr > th:last-child,
  tr th:last-child,
  tr td:last-child {
    padding-right: $card-spacer-x;
  }
}
.white-space-nowrap {
  width: 1px;
  white-space: nowrap;
}
th {
  font-weight: $font-weight-semi-bold;
}
td .dropdown-toggle {
  &:after,
  &:before {
    display: none;
  }
}
.table-dashboard {
  overflow: hidden;
  th {
    border-bottom-width: 1px !important;
  }
}

body {
  --falcon-pagination-disabled-border-color: #edf2f9;
  --falcon-pagination-border-color: #d8e1f0;
}
// .table-responsive {
//   @extend .scrollbar;
// }
