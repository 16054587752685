.stationAdminActions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;
}

.DataSourceCard {
  &.not-visible {
    opacity: 0.33;

    &:hover {
      opacity: 0.66;
    }
  }

  .admin-actions {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 25%;
    background-color: rgba(255, 255, 255, 0.5);
    text-align: right;
    opacity: 0;
    transition: opacity 0.2s;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    z-index: 1;

    &.visible {
      opacity: 1;
    }
    &:hover {
      opacity: 1 !important;
    }

    svg {
      cursor: pointer;
      opacity: 0.5;
      margin-right: 0px !important;
      &:hover {
        opacity: 1;
      }
    }
    .configSelector {
      width: fit-content;
      display: flex;
      flex-direction: column;
      position: absolute;
      background-color: var(--falcon-alert-light-background);
      background-clip: border-box;
      border: 1px solid var(--falcon-card-border-color);
      border-radius: 0.375rem;
      padding: 8px 8px;
      z-index: 1;
      top: 26px;
      row-gap: 8px;

      > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          padding-right: 5px;
          white-space: nowrap;
        }
        .form-control {
          padding: 2px;
        }
      }
      .config-button {
        justify-content: flex-end;
        button {
          width: 100%;
          font-size: 11px;
          padding: 4px 3px;
        }
      }
    }
  }

  &:hover .admin-actions {
    opacity: 0.5;
  }

  &.alert-triggered {
    opacity: 1;
    &:hover,
    &:hover .admin-actions {
      opacity: 1;
    }
  }

  .additional-data-sources {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 16px;
    flex-wrap: wrap;
    column-gap: 16px;
  }
}

.exporter-source-config {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 14px;
}

.exporter-source-name {
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  margin-top: 4px;
}

.IframeBody {
  position: relative;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.DailyAveragesTable {
  table {
    display: block;
    max-height: 512px;
    overflow-y: auto;
    th {
      position: sticky;
      top: 0;
      background-color: white;
      min-width: 160px;
      font-size: 12px;
    }
  }
}
