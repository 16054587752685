.stationListTable {
  & > tbody > tr:nth-of-type(even) {
    box-shadow: inset 0 0 0 9999px #f9fafd;
  }
  & > tbody > tr:nth-of-type(even),
  & > tbody > tr:nth-of-type(odd) {
    &.inactive,
    &.hold,
    &.alert {
      & > *,
      a {
        --falcon-table-accent-bg: transparent;
      }
    }
    &.inactive {
      box-shadow: none;
      background-color: rgba(150, 150, 150, 0.4);
      a {
        box-shadow: none;
        color: rgba(128, 128, 128, 0.6);
      }
    }
    &.hold {
      box-shadow: none;
      background-color: rgba(245, 128, 62, 0.4);
    }
    &.alert {
      td:nth-child(4) {
        box-shadow: none;
        background-color: rgba(200, 0, 0, 0.2);
        a {
          color: rgba(200, 0, 0, 0.6);
        }
      }
    }
  }
}
.stations-map {
  min-height: 73vh;
  display: flex;
  > div,
  > div > div > div {
    height: calc(73vh - 40px) !important ;
  }
}
.station-table-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
#station-search {
  padding: 0.3125rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--falcon-input-color);
  background-color: var(--falcon-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--falcon-input-border-color);
  appearance: none;
  border-radius: 0.25rem;
  box-shadow: var(--falcon-box-shadow-inset);
}
