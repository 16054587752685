.editable-dashboard-container {
  max-height: calc(100vh - 135px);
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px dashed rgb(200, 200, 200);
}
.widget-column {
  position: relative;
}
.row-add-widget {
  max-width: 200px;
  .add-widget-button {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(250, 250, 250);
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}
.dashboard-station-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.station-title-action-buttons {
  display: flex;
}
.dashboard-widget-button {
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding-left: 8px;
  padding-top: 3px;
  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
}
.delete-widget-button {
  @extend .dashboard-widget-button;
  z-index: 1;
  left: calc(100% - 40px);
  top: 4px;
}

.widget-controls {
  top: 8px;
  right: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  position: absolute;
}

.widget-annotations-checkbox {
  @extend .dashboard-widget-button;
  width: 20px;
  height: 20px;
}

.widget-annotations-checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 16px;
}

.widget-annotations-checkbox-label {
  font-size: 14px;
  color: #666;
  margin: 0;
  cursor: pointer;
}

.floating-footer {
  position: fixed;
  bottom: -60px;
  width: 100%;
  left: 0px;
  z-index: 1020;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-bottom: 0;
  transition: bottom 0.5s ease-in-out;

  &.visible {
    bottom: 16px;
  }

  > div {
    display: flex;
    justify-content: center;
    width: 320px;
    border-radius: 8px;
    background-color: rgb(250, 250, 250);
    padding: 8px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  }

  button + button {
    margin-left: 8px;
  }
}
.add-station-button {
  position: relative;
  left: 8px;
  bottom: 8px;
}
.hidden-title {
  opacity: 0.5;
}
.dashboard-header-title {
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.dashboard-rename-button {
  display: flex;
  align-items: center;
  padding-left: 0px;
  padding-top: 0px;
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
}
.dashboard-organization-picker {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 8px;
  span {
    font-size: 1rem;
    flex-shrink: 0;
  }
  select {
    width: 50%;
  }
}
.dashboard-row-buttons {
  display: flex;
  gap: 16px;
}
.copy-to-clipboard {
  &.standalone {
    padding-left: 20px;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  span {
    font-weight: 500;
    margin-left: 4px;
    font-size: 11px;
    text-transform: uppercase;
  }
}
