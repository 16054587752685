// Bleh, these just apply to all datepickers
.react-datepicker {
  //.react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--outside-month) {
  //  font-weight: bold;
  //}
  .react-datepicker__day:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month):not(.react-datepicker__day--disabled),
  .react-datepicker__time-list-item:not(.react-datepicker__time-list-item--selected):not(.react-datepicker__time-list-item--disabled) {
    color: var(--falcon-1000) !important;
  }
  .react-datepicker__day.react-datepicker__day--disabled,
  .react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
    color: var(--falcon-300) !important;
    cursor: not-allowed;
  }
  .react-datepicker__day.react-datepicker__day--outside-month:not(.react-datepicker__day--disabled) {
    color: var(--falcon-800) !important;
  }
}

.DateTimeRangeSelector {
  .date-inputs {
    display: flex;
    flex-direction: row;
    gap: 5px;

    .react-datepicker-popper {
      // 401 to go over the map and 1000 to go over map buttons
      z-index: 1000;
    }
  }
}
