/* -------------------------------------------------------------------------- */
/*                                  Override                                  */
/* -------------------------------------------------------------------------- */
.dark {
  @each $color, $value in $theme-all-colors {
    .dark__bg-#{$color} {
      background-color: $value !important;
    }
    .dark__text-#{$color} {
      color: $value !important;
    }
  }

  ::selection {
    background-color: $warning;
  }
}
