.sectionTables {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 500px;
}
.alignRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button + button {
    margin-left: 10px;
  }
}
.uploadHeader {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;
}
.templateButton {
  display: flex;
  gap: 20px;
  align-items: center;
}
