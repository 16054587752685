.annotationTables {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 400px;
}
.alignRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.colorPreview {
  display: flex;
  align-items: center;
  gap: 5px;
  .box {
    width: 20px;
    height: 20px;
    border-radius: 5px;
  }
}
