.uploadHeader,
.uploadActions {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;
}

.uploadActions {
  max-width: 400px;
}
