.filter-data-form {
  width: 100%;
  > div:not(:first-child) {
    margin-top: 10px;
  }
}

.selected-data-filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;
  padding-top: 10px;
  .filters-inputs {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
  }
}

.config-title {
  svg {
    margin-left: 5px;
  }
}

.selected-data-row {
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  .selected-data-title {
    font-size: 20px;
  }
  .selected-data {
    border: solid 1px #d8e2ef;
    padding: 20px;
    border-radius: 10px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    .selected-date {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .selected-filters {
      display: flex;
      flex-direction: column;
      span {
        color: #344050;
        font-size: 12px;
      }
      .no-datapoints {
        color: #aa0000;
        font-weight: bold;
      }
    }
  }

  .selected-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    .form-check {
      margin-bottom: 0px !important;
      label {
        line-height: 1rem !important;
      }
    }
  }
}
.hidden-data-row {
  display: flex;
  flex-direction: column;
  background-color: #d8e2ef;

  margin-top: 10px;
  padding: 20px;
  border-radius: 10px;
  .hidden-data-title {
    font-size: 20px;
  }
  .hidden-data {
    display: flex;
    padding-top: 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .hidden-filters {
      display: flex;
      flex-direction: column;
      span {
        color: #344050;
        font-size: 12px;
      }
    }
    &:not(:last-child) {
      border-bottom: solid 1px #344050;
    }
  }
}

.select-spikes {
  &,
  label {
    margin-bottom: 0px !important;
  }
}

.filters-submit-button {
  margin-top: 10px;
}
