.StationAuditPage {
  .activeRow {
    cursor: pointer;
    &:hover {
      background-color: var(--falcon-border-color);
    }
    .recordCell {
      tr span:first-child {
        color: var(--falcon-400);
        font-size: 12px;
        padding-right: 4px;
      }
    }
    .capitalize::first-letter {
      text-transform: capitalize;
    }
  }
  .spinnerWrapper {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .expandedLogRecord {
    padding: 0px;
    .expandedLogContent {
      display: block;
      width: 100%;
      max-height: 0px;
      overflow: hidden;
      &.visible {
        max-height: 100vh;
        transition: max-height 500ms ease-in;
      }
      .wrapperCell {
        padding: 0px;
      }
    }
  }

  .pagination-row {
    border-bottom: 0;
  }
  ul.pagination {
    li {
      cursor: pointer;
    }
    div.active-page-button {
      background-color: var(--falcon-pagination-hover-bg);
      color: var(--falcon-pagination-hover-color);
      border: solid 1px var(--falcon-pagination-hover-bg);
    }
  }
}

.table.auditChangesTable {
  width: initial;
  .changedValue {
    border-radius: 4px;
    color: #000;
    padding: 10px 20px;
    vertical-align: middle;
    &.previous {
      background-color: #fcc;
    }
    &.next {
      background-color: #cfc;
    }
  }
}

.pagination-skipped-pages {
  display: flex;
  align-items: flex-end;
  height: 42px;
  margin: 0px 5px;
}
