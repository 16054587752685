.actionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button + button {
    margin-left: 10px;
  }
}
.clippedColumn {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.numericColumn {
  min-width: 100px;
  width: 100px;
}
