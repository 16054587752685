.ConversionTablePage {
  .channelId svg {
    display: none;
  }

  @media only screen and (max-width: 760px) {
    table {
      th {
        display: none;
      }
      tr {
        &.hideChannel td:not(:first-child) {
          display: none;
        }
        td {
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          border: none;
          &::before {
            content: attr(data-label);
            display: block;
            width: 50%;
          }
          &:first-child {
            padding-left: 0.75rem;
          }
          &:last-child {
            padding-bottom: 1.75rem;
            margin-bottom: 1.75rem;
            border-bottom: solid 1px #5e6e82;
          }
        }
      }
    }
    .channelId {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      svg {
        display: block;
        margin-left: 10px;
      }
    }
  }
}
