.intervalUnitSelector {
  select {
    height: 29px;
    font-size: 14px;
    line-height: 14px;
  }
}
.alert-list {
  display: flex;
  flex-direction: column;
  gap: 5px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 10px;
    border: 1px solid #e5e5e5;
    cursor: pointer;
    background-color: #f9f9f9;
    &:hover {
      background-color: #f2f2f2;
    }
    > div {
      width: 140px;
      &:last-child {
        text-align: end;
      }
    }
  }
}

.alertLevelSelect {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.alertColorPreview {
  width: 60px;
  height: 35px;
  border-radius: 0.25rem;
  opacity: 0.8;
}
