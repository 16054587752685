.UserEditPage {
  .tableActionButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 36px;
  }
  .actionButtons,
  .tableActionButtons {
    button + button {
      margin-left: 10px;
    }
    button + svg {
      margin-left: 10px;
    }
  }
  .existingOrganizationTable {
    max-width: 600px;
    .pendingRemoval {
      background-color: #e63757;
      color: #ffffff;
    }
    td:first-child {
      width: 300px;
    }
    td:last-child {
      text-align: right;
    }
  }
}
